<template>
  <WidgetContainer style="overflow:scroll" :loading="loading" :error="error">
    <div v-if="!loading">
      <a-table
        v-if="columns.length && data.length && !error"
        class="ant-table-striped"
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: options.basicPageSize }"
        :fixed="true"
        :scroll="{ y: options.scrollHeight, x: options.scrollWidth }"
        :rowKey="(r, index) => index"
        size="small"
      ></a-table>
    </div>
  </WidgetContainer>
</template>

<script>
import WidgetContainer from "../WidgetContainer";
import * as utils from "../../utils";

export default {
  inheritAttrs: false,
  components: { WidgetContainer },
  props: {
    widget: Object
  },
  data() {
    return {
      loading: true,
      columns: [],
      data: [],
      error: null,
      value: null
    };
  },
  computed: {
    options() {
      return this.widget.options || {};
    }
  },
  mounted() {
    this.reload();
  },
  watch: {
    "widget.datasource"() {
      this.reload();
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      try {
        if (!utils.validateDatasource(this.widget.datasource)) {
          this.error = { message: "未设置数据源", detail: "" };
          return;
        }
        const { data } = await utils.getWidgetData(this.widget.datasource);
        this.columns = data.columns.map((item, index) => {
          let target = {
            id: index,
            title: item.columnName,
            key: item.columnId,
            dataIndex: item.columnId,
            width: this.options.basicColumnsWidth,
            ellipsis: this.options.columnsEllipsis
          };
          if (this.options.fixedLeftColumns && index == 0) {
            target["fixed"] = "left";
          }
          return target;
        });

        this.data = data.records.map(item => {
          let obj = {};
          data.columns.forEach((item2, index2) => {
            obj[item2.columnId] = item[index2];
          });
          return obj;
        });
        this.error = null;
      } catch (err) {
        this.error = utils.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.table-wrapper {
  margin: 12px;
}
.ant-table-placeholder {
  border-bottom: none !important;
}
.ant-table-thead > tr > th {
  white-space: nowrap;
}
.ant-table-row td {
  white-space: nowrap;
}
.ant-table td {
  white-space: nowrap;
}
/deep/.ant-table-tbody > :nth-child(2n) {
  background-color: rgb(242, 242, 242);
}
/deep/.ant-table-pagination {
  margin-bottom: 0px;
}
</style>
