import { Chassis, toDataset, labelFormatter } from "../common";
import { IWidgetOptions } from "./interface";

export function generateCompose(opts) {
  function make(fn) {
    return (config, dataset, options) => {
      return typeof fn === "function"
        ? fn(config, dataset, options) || config
        : config;
    };
  }
  opts = opts || {};
  const customize = {
    xAxis: make(opts.xAxis),
    yAxis: make(opts.yAxis),
    legend: make(opts.legend),
    series: make(opts.series)
  };
  return function() {
    const dataset = toDataset(this.data);
    const options = new IWidgetOptions(this.widget.options);
    return {
      xAxis: customize.xAxis(
        {
          type: "value",
          axisLabel: {
            interval: options.forceCategoryAxisLableVisible ? 0 : "auto",
            hideOverlap: true
          }
        },
        dataset,
        options
      ),
      yAxis: customize.yAxis(
        {
          type: "category",
          axisLabel: {
            interval: options.forceCategoryAxisLableVisible ? 0 : "auto",
            hideOverlap: true
          }
        },
        dataset,
        options
      ),
      legend: customize.legend(
        {
          show: options.showLegend,
          itemWidth: 10,
          itemHeight: 10,
          icon: "rect"
        },
        dataset,
        options
      ),
      dataset,
      series: dataset.series.map(item => {
        const seriesItem = {
          type: "bar",
          colorBy: options.colorByData ? "data" : "series",
          showSymbol: false,
          name: item.name,
          label: {
            show: options.showSeriesLabel,
            position: "right",
            overflow: "truncate",
            formatter: labelFormatter
          },
          labelLayout: { hideOverlap: true },
          encode: {
            x: item.name,
            y: dataset.dimensionName
          }
        };
        return customize.series(seriesItem, dataset, options);
      })
    };
  };
}

export default {
  mixins: [Chassis],
  methods: { compose: generateCompose() }
};
