export function sortSource(source) {
  let target = source;
  if (isNaN(target[0][0]) && !isNaN(Date.parse(target[0][0]))) {
    target = target.sort((a, b) => {
      return Date.parse(b[0]) - Date.parse(a[0]);
    });
  }
  return target;
}

export default sortSource;
