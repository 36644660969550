import { Chassis, toDataset, pieTooltipFormatter } from "../common";
import { IWidgetOptions } from "./interface";

export function generateCompose(opts) {
  function make(fn) {
    return (config, dataset, options) => {
      return typeof fn === "function"
        ? fn(config, dataset, options) || config
        : config;
    };
  }
  opts = opts || {};
  const customize = {
    legend: make(opts.legend),
    series: make(opts.series)
  };
  return function() {
    const dataset = toDataset(this.data);
    const options = new IWidgetOptions(this.widget.options);
    return {
      legend: customize.legend(
        {
          show: options.showLegend,
          itemWidth: 12,
          itemHeight: 12,
          icon: "circle"
        },
        dataset,
        options
      ),
      tooltip: {
        formatter: pieTooltipFormatter
      },
      dataset,
      series: dataset.series.map(item => {
        const seriesItem = {
          type: "pie",
          name: item.name,
          label: {
            show: options.showSeriesLabel,
            formatter: "{b}: {d}%"
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 2,
            borderRadius: 4
          },
          radius: [0, "60%"],
          encode: {
            itemName: dataset.dimensionName,
            value: item.name
          }
        };
        return customize.series(seriesItem, dataset, options);
      })
    };
  };
}

export default {
  mixins: [Chassis],
  methods: { compose: generateCompose() }
};
