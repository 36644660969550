import { Chassis, toDataset, labelFormatter } from "../common";
import { IWidgetOptions } from "./interface";

export default {
  mixins: [Chassis],
  methods: {
    compose() {
      const dataset = toDataset(this.data);
      const options = new IWidgetOptions(this.widget.options);
      return {
        xAxis: {
          type: "category",
          axisLabel: {
            interval: options.forceCategoryAxisLableVisible ? 0 : "auto",
            hideOverlap: true
          }
        },
        yAxis: [
          {
            type: "value"
          },
          options.dualYAxis ? { type: "value" } : null
        ].filter(Boolean),
        tooltip: {
          trigger: "axis"
        },
        legend: {
          show: options.showLegend,
          itemHeight: 8,
          itemWidth: 18,
          data: dataset.series.map(item => {
            const meta = item.columnMeta || {};
            return {
              name: item.name,
              icon: meta.tag == "line" ? null : "rect"
            };
          })
        },
        dataset,
        series: dataset.series.map(item => {
          const meta = item.columnMeta || {};
          return {
            type: meta.tag === "line" ? "line" : "bar",
            colorBy: options.colorByData ? "data" : "series",
            symbolSize: 0.01,
            name: item.name,
            smooth: options.smoothLine,
            connectNulls: true,
            yAxisIndex: meta.tag === "line" && options.dualYAxis ? 1 : 0,
            label: {
              show: options.showSeriesLabel,
              position: "top",
              overflow: "truncate",
              formatter: labelFormatter
            },
            labelLayout: { hideOverlap: true },
            encode: {
              x: dataset.dimensionName,
              y: item.name
            }
          };
        })
      };
    }
  }
};
