import merge from "lodash/merge";
import debounce from "lodash/debounce";
import * as echarts from "echarts";
import * as utils from "../utils";
import { IWidgetColumn, valueFormatter } from "./common";
import WidgetContainer from "./WidgetContainer";

/** @type {import("vue").ComponentOptions} */
export const Chassis = {
  name: "playground",
  inheritAttrs: false,
  props: {
    widget: Object
  },
  data() {
    return {
      loading: true,
      error: null
    };
  },
  render() {
    return (
      <WidgetContainer loading={this.loading} error={this.error}>
        <div ref="stage" style="width: 100%; height: 100%"></div>
      </WidgetContainer>
    );
  },
  mounted() {
    const el = this.$refs.stage;
    const instance = echarts.init(el);
    this.instance = instance;
    this.reload();
    const observer = new ResizeObserver(
      debounce(() => instance.resize(), 200, {
        leading: false,
        trailing: true
      })
    );
    observer.observe(el);
    this.observer = observer;
  },
  destroyed() {
    this.instance.dispose();
    this.observer.disconnect();
  },
  watch: {
    "widget.datasource": debounce(function() {
      this.reload();
    }, 100),
    "widget.options": debounce(function() {
      this.draw();
    }, 300)
  },
  methods: {
    // 加载图表数据
    async reload() {
      this.$emit("loading", true);
      this.loading = true;
      try {
        if (!utils.validateDatasource(this.widget.datasource)) {
          this.error = { message: "未设置数据源", detail: "" };
          return;
        }
        const { data } = await utils.getWidgetData(this.widget.datasource);
        this.data = {
          columns: data.columns.map(IWidgetColumn.from),
          records: data.records
        };
        this.error = null;
        this.draw();
      } catch (err) {
        this.error = utils.error(err);
        this.$emit("error", err.response || err);
        console.error("echarts", err, this.widget);
      } finally {
        this.$emit("loading", false);
        this.loading = false;
      }
    },
    // 合成 echarts 选项
    compose() {
      return {};
    },
    draw() {
      const widgetOptions = this.widget.options || {};
      const baseEchartOptions = {
        color: utils.themeProvider.getColors(widgetOptions.theme),
        grid: {
          left: 20,
          right: 20,
          top: 35,
          bottom: 15,
          containLabel: true
        },
        legend: { type: "scroll" },
        tooltip: { appendToBody: true, valueFormatter },
        dataset: { sourceHeader: false }
      };
      const options = merge(baseEchartOptions, this.compose());
      this.instance.setOption(options, true);
    },
    resize() {
      this.instance.resize();
    }
  }
};

export default Chassis;
