<template>
  <div class="widget-playground">
    <slot></slot>
    <a-spin v-if="loading" />
    <a-empty
      v-else-if="error"
      :image="ERROR_IMAGE"
      :image-style="{ height: '90px', marginBottom: 0 }"
    >
      <span slot="description">{{ error.message }}</span>
    </a-empty>
  </div>
</template>

<script>
import ERROR_IMAGE from "@/assets/images/ai_error.svg";

export default {
  props: {
    loading: Boolean,
    error: Object
  },
  data() {
    return { ERROR_IMAGE };
  }
};
</script>

<style lang="less" scoped>
.widget-playground {
  position: relative;
  width: 100%;
  height: 100%;
  .ant-spin {
    position: absolute;
    // inset 是 top / right / bottom / left 的速写语法
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.45);
  }
  .ant-empty {
    position: absolute;
    inset: 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.25);
    overflow: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
