import { Chassis, toDataset } from "../common";
import { IWidgetOptions } from "./interface";

export function generateCompose(opts) {
  function make(fn) {
    return (config, dataset, options) => {
      return typeof fn === "function"
        ? fn(config, dataset, options) || config
        : config;
    };
  }
  opts = opts || {};
  const customize = {
    legend: make(opts.legend),
    series: make(opts.series)
  };
  return function() {
    const dataset = toDataset(this.data);
    const options = new IWidgetOptions(this.widget.options);
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}"
      },
      legend: customize.legend(
        {
          show: options.showLegend
        },
        dataset,
        options
      ),
      series: dataset.series.map(() => {
        const seriesItem = {
          name: dataset.dimensionName,
          type: "funnel",
          gap: options.seriesGap,
          label: {
            normal: {
              show: options.showSeriesLabel,
              position: options.seriesLabelDerc,
              textStyle: {
                fontSize: 15
              },
              formatter: function(series) {
                if (options.seriesLabelData == "showConversion") {
                  let target = "100%";
                  if (series.dataIndex > 0) {
                    let a = Math.floor(
                      dataset.source[0][series.dataIndex + 1] /
                        dataset.source[0][series.dataIndex]
                    );
                    target = (a * 100) / 100 + "%";
                  }
                  return options.showSeriesLabelName
                    ? `转化率 ${target}`
                    : `${target}`;
                } else if (options.seriesLabelData == "showData") {
                  return options.showSeriesLabelName
                    ? `${series.name} ${series.value}`
                    : `${series.value}`;
                } else if (options.seriesLabelData == "showProportion") {
                  return options.showSeriesLabelName
                    ? `占比 ${series.percent}%`
                    : `${series.percent}%`;
                }
              }
            }
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: "solid"
            }
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1
          },
          min: 0,
          minSize: `${options.seriesMinSize}%`,
          maxSize: `${options.seriesMaxSize}%`,
          sort: "none",
          data: dataset.series.map((item, index) => {
            let addValue = 0;
            dataset.source.forEach(i => {
              addValue = addValue + Number(i[index + 1]);
            });
            return {
              value:
                typeof addValue === "number" && isNaN(addValue) ? 0 : addValue,
              name: item.columnName
            };
          })
        };
        return customize.series(seriesItem, dataset, options);
      })
    };
  };
}

export default {
  mixins: [Chassis],
  methods: { compose: generateCompose() }
};
