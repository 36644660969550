/**
 * 将接口数据根据维度信息转换成 echarts 的 dataset 格式
 * @param {import("./type").IResponse} responseData 图表数据接口返回内容
 * @returns {import("./type").IDatasetDescriber} dataset 及相关元信息
 */
export function toDataset(responseData) {
  const { columns, records } = responseData;
  /**
   * 维度列，echarts 图表中一般只有一个
   * 分组列，echarts 图表中最多一个
   * 数值列，至少一个
   * @date 2022-08-16
   * */
  const dimensionColumn = columns.find(item => item.isRow);
  if (!dimensionColumn) {
    return {
      dimensions: [],
      source: [],
      dimensionColumn,
      dimensionName: "",
      series: []
    };
  }
  const valueColumns = columns.filter(item => item.isValue);
  // 无分组时，接口返回的数据即可作为 dataset
  if (!columns.some(item => item.isGroup)) {
    return {
      dimensions: columns,
      source: records,
      dimensionColumn,
      dimensionName: dimensionColumn.columnName,
      series: valueColumns
    };
  }
  const keyOfGroup =
    valueColumns.length > 1 ? (a, b) => [a, b].join("-") : a => a;
  const groupColumn = columns.find(item => item.isGroup);
  const groupValues = new Set();
  const sourceMap = new Map();
  for (const row of records) {
    const dimensionValue = row[dimensionColumn.columnIndex];
    const groupValue = row[groupColumn.columnIndex];
    groupValues.add(groupValue);
    /** @type {Map} */
    const newRecord = sourceMap.get(dimensionValue) || new Map();
    for (const valueColumn of valueColumns) {
      newRecord.set(
        keyOfGroup(groupValue, valueColumn.name),
        row[valueColumn.columnIndex]
      );
    }
    sourceMap.set(dimensionValue, newRecord);
  }
  const groupDimensions = Array.from(groupValues, groupValue =>
    valueColumns.map(valueColumn => {
      const newColumn = valueColumn.clone();
      // 存在分组时，维度名称为 分组值-指标列名，只有一个指标列时不展示指标名
      // 例如 广州-销量、深圳-销量、广州-销售额、深圳-销售额
      newColumn.name = keyOfGroup(groupValue, valueColumn.columnName);
      return newColumn;
    })
  ).reduce((arr, item) => arr.concat(item), []);
  return {
    dimensions: [dimensionColumn].concat(...groupDimensions),
    source: Array.from(sourceMap, ([dimensionValue, recordMap]) => {
      const recordValues = groupDimensions.map(groupDimension =>
        recordMap.get(groupDimension.name)
      );
      return [dimensionValue].concat(recordValues);
    }),
    dimensionColumn,
    dimensionName: dimensionColumn.columnName,
    series: groupDimensions
  };
}

export default toDataset;
