import { Chassis } from "../common";
import { generateCompose } from "../pie-basic/Playground";

export default {
  mixins: [Chassis],
  methods: {
    compose: generateCompose({
      series: config => {
        config.roseType = "radius";
        config.radius = ["15%", "60%"];
      }
    })
  }
};
