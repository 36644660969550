<template>
  <WidgetContainer :loading="loading" :error="error">
    <div class="widget" v-if="!loading">
      <div>
        <div class="color" :style="styles">
          <div
            v-if="options.title"
            style="margin-bottom:20px"
            class="description"
          >
            {{ options.title }}
          </div>
          <div class="value" style="text-align:center" :title="formattedValue">
            {{ formattedValue }}
          </div>
        </div>

        <div class="color" style="text-align:center" :style="styles2">
          <span v-if="options.title2" class="description">
            {{ options.title2 }}
          </span>
          <span class="value" :title="formattedValue2">
            <a-icon v-if="options.format2 == 'up'" type="arrow-up" />
            <a-icon v-if="options.format2 == 'down'" type="arrow-down" />
            <a-icon v-if="options.format2 == 'add'" type="plus" />
            <a-icon v-if="options.format2 == 'reduce'" type="minus" />
            {{ formattedValue2 }}
          </span>
        </div>
      </div>
    </div>
  </WidgetContainer>
</template>

<script>
import numbro from "numbro";
import WidgetContainer from "../WidgetContainer";
import * as utils from "../../utils";

export default {
  inheritAttrs: false,
  components: { WidgetContainer },
  props: {
    widget: Object
  },
  data() {
    return {
      loading: true,
      error: null,
      value: null,
      value2: null
    };
  },
  computed: {
    options() {
      return this.widget.options || {};
    },
    styles() {
      const options = this.options;
      return {
        "--color": options.color,
        "--font-size": options.fontSize + "px"
      };
    },
    styles2() {
      const options = this.options;
      return {
        "--color": options.color2,
        "--font-size": options.fontSize2 + "px"
      };
    },
    formattedValue() {
      return this.formatValue(
        this.value[0],
        this.options.format,
        this.options.mantissa,
        this.options.thousandSeparated
      );
    },
    formattedValue2() {
      return this.formatValue(
        this.value[1],
        this.options.format2,
        this.options.mantissa2,
        this.options.thousandSeparated2
      );
    }
  },
  mounted() {
    this.reload();
  },
  watch: {
    "widget.datasource"() {
      this.reload();
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      try {
        if (!utils.validateDatasource(this.widget.datasource)) {
          this.error = { message: "未设置数据源", detail: "" };
          return;
        }
        const { data } = await utils.getWidgetData(this.widget.datasource);
        const records = data.records || [];
        const defaultRecord = records[0] || [];
        this.value = defaultRecord || [null, null];
        this.error = null;
      } catch (err) {
        this.error = utils.error(err);
      } finally {
        this.loading = false;
      }
    },
    formatValue(value, format, mantissa, thousandSeparated) {
      const nb = numbro(value);
      if (!nb.value() && nb.value() !== 0) {
        return value;
      }
      const fm = { thousandSeparated: !!thousandSeparated };
      if (format === "%") {
        fm.output = "percent";
      }
      if (mantissa || mantissa === 0) {
        fm.mantissa = mantissa;
      }
      const nbval = nb.format(fm);
      if (format === "¥") {
        return "¥ " + nbval;
      }
      if (format === "$") {
        return "$ " + nbval;
      }
      return nbval;
    }
  }
};
</script>

<style lang="less" scoped>
.color {
  color: var(--color);
  font-size: var(--font-size);
  text-align: left;
}
.widget {
  --color: rgb(73, 84, 230);
  --font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  animation: start 0.3s 0.4s backwards;
  .value {
    font-size: 2.285em;
    font-family: Din alternate, Din pro;
    width: 100%;
    padding: 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .value,
  .description {
    width: 100%;
    padding: 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @keyframes start {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
