import { Chassis, toDataset, labelFormatter, sortSource } from "../common";
import { IWidgetOptions } from "./interface";

export function generateCompose(opts) {
  function make(fn) {
    return (config, dataset, options) => {
      return typeof fn === "function"
        ? fn(config, dataset, options) || config
        : config;
    };
  }
  opts = opts || {};
  const customize = {
    xAxis: make(opts.xAxis),
    yAxis: make(opts.yAxis),
    legend: make(opts.legend),
    series: make(opts.series)
  };
  return function() {
    const dataset = toDataset(this.data);
    dataset.source = sortSource(dataset.source);
    const options = new IWidgetOptions(this.widget.options);
    if (options.showReverse) {
      dataset.source = dataset.source.reverse();
    }
    return {
      xAxis: customize.xAxis(
        {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            interval: options.forceCategoryAxisLableVisible ? 0 : "auto",
            hideOverlap: true
          }
        },
        dataset,
        options
      ),
      yAxis: customize.yAxis(
        {
          type: "value"
        },
        dataset,
        options
      ),
      tooltip: {
        trigger: "axis"
      },
      legend: customize.legend(
        {
          show: options.showLegend,
          itemWidth: 18,
          itemHeight: 6
        },
        dataset,
        options
      ),
      dataset,
      series: dataset.series.map(item => {
        const seriesItem = {
          type: "line",
          colorBy: options.colorByData ? "data" : "series",
          // 不设置 symbol 无法显示 label，因此设置一个小小的 symbol
          // symbol: "circle",
          symbolSize: 0.01,
          name: item.name,
          connectNulls: true,
          label: {
            show: options.showSeriesLabel,
            position: "top",
            overflow: "truncate",
            formatter: labelFormatter
          },
          labelLayout: { hideOverlap: true },
          encode: {
            x: dataset.dimensionName,
            y: item.name
          }
        };
        return customize.series(seriesItem, dataset, options);
      })
    };
  };
}

export default {
  mixins: [Chassis],
  methods: { compose: generateCompose() }
};
