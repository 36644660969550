<template>
  <WidgetContainer :loading="loading" :error="error">
    <div v-if="!loading" class="widget" :style="styles">
      <span class="value" :title="formattedValue">{{ formattedValue }}</span>
      <span v-if="options.title" class="description">{{ options.title }}</span>
    </div>
  </WidgetContainer>
</template>

<script>
import numbro from "numbro";
import WidgetContainer from "../WidgetContainer";
import * as utils from "../../utils";

export default {
  inheritAttrs: false,
  components: { WidgetContainer },
  props: {
    widget: Object
  },
  data() {
    return {
      loading: true,
      error: null,
      value: null
    };
  },
  computed: {
    options() {
      return this.widget.options || {};
    },
    styles() {
      const options = this.options;
      return {
        "--color": options.color,
        "--font-size": options.fontSize + "px"
      };
    },
    formattedValue() {
      const nb = numbro(this.value);
      if (!nb.value() && nb.value() !== 0) {
        return this.value;
      }
      const options = this.options;
      const fm = { thousandSeparated: !!options.thousandSeparated };
      if (options.format === "%") {
        fm.output = "percent";
      }
      if (options.mantissa || options.mantissa === 0) {
        fm.mantissa = options.mantissa;
      }
      const nbval = nb.format(fm);
      if (options.format === "¥") {
        return "¥ " + nbval;
      }
      if (options.format === "$") {
        return "$ " + nbval;
      }
      return nbval;
    }
  },
  mounted() {
    this.reload();
  },
  watch: {
    "widget.datasource"() {
      this.reload();
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      try {
        if (!utils.validateDatasource(this.widget.datasource)) {
          this.error = { message: "未设置数据源", detail: "" };
          return;
        }
        const { data } = await utils.getWidgetData(this.widget.datasource);
        const records = data.records || [];
        const defaultRecord = records[0] || [];
        this.value = defaultRecord[0] || null;
        this.error = null;
      } catch (err) {
        this.error = utils.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.widget {
  --color: rgb(73, 84, 230);
  --font-size: 14px;
  color: var(--color);
  font-size: var(--font-size);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  animation: start 0.3s 0.4s backwards;
  .value {
    font-size: 2.285em;
    font-family: Din alternate, Din pro;
  }
  .value,
  .description {
    width: 100%;
    padding: 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @keyframes start {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
