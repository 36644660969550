import { Chassis } from "../common";
import { generateCompose } from "../bar-basic/Playground";

export default {
  mixins: [Chassis],
  methods: {
    compose: generateCompose({
      xAxis: config => {
        config.type = "category";
      },
      yAxis: config => {
        config.type = "value";
      },
      series: config => {
        config.label.position = "top";
        const y = config.encode.y;
        config.encode.y = config.encode.x;
        config.encode.x = y;
      }
    })
  }
};
