export function valueFormatter(value) {
  let res = Number(value);
  return isNaN(res) ? value : res.toLocaleString();
}

export function labelFormatter(options) {
  // 第一项是数据名称，所以要 +1
  const index = options.seriesIndex + 1;
  return valueFormatter(options.value[index]);
}

export function pieTooltipFormatter(options) {
  const index = options.seriesIndex + 1;
  const value = valueFormatter(options.value[index]);
  return `
    <div style="margin-bottom: 10px">${options.marker} ${options.name}</div>
    <div>数值 <span style="float: right; margin-left: 20px">${value}</span></div>
    <div>占比 <span style="float: right; margin-left: 20px">${options.percent}%</span></div>
    <div style="clear: both"></div>
  `;
}
